<template>
    <EstructuraBasica class="mt-n15">
      <template v-slot:main>
       <v-row>
        <v-col>
          <ToolbarTitle  v-on:retroceder="$router.go(-1)" :title="'Flujos de trabajo'" v-on:crear="crear" />
        </v-col>
        
       </v-row>
        <v-row v-if="dialogWorkFlow==false" class="mx-15 mt-1">
            <v-col>
                 <v-text-field v-model="search" rounded dense filled label="Busca un flujo de trabajo" prepend-icon="mdi-magnify" ></v-text-field>
                 <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row v-if="dialogWorkFlow==false" class="ml-15">
            <v-col cols="12" sm="4" md="3">
              <v-card elevation="4" min-height="350">
                <v-toolbar color="indigo darken-4" dark>
                  <v-toolbar-title>Crea un nuevo flujo</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <p>Los flujos de trabajo son aquellos recorridos que realizan los documentos por las 
                    distintas oficinas, areas o sectores de su entidad. Al agregar un flujo de trabajo
                    permitira que sus empleados puedan elegirlos en el momento de que llegue un nuevo caso o tarea
                  </p>
                </v-card-text>
                <v-card-actions>
                   <v-btn @click="dialogWorkFlow=true;$store.state.admin.proceso='crear'" block color=indigo text> Crear <v-icon>mdi-plus</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="8" md="9">
              <v-row>
                <v-col v-for="(flow,index) in itemsFilter" :key="index">
                  <WorkFlow class="mx-auto" v-on:eliminar="eliminar(flow,index)" v-on:editar="editar(flow,index)" :flow=flow />
                </v-col>
              </v-row>
            </v-col>
        </v-row>
        <v-row v-else class="mx-15 mt-2" >
         <v-card class="mx-6" width="95%">
          <v-toolbar color="indigo" dark>
              <v-btn  @click="dialogWorkFlow=false;nodes=[];$store.state.admin.workflow=[]" icon > <v-icon> mdi-arrow-left </v-icon> </v-btn>
              <v-toolbar-title>Creacion del flujo de trabajo</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text :disabled="!valid" @click="crear">Guardar</v-btn>
          </v-toolbar>
          <v-row>
              <v-col cols="12" sm="4">
                <v-card>
                  <v-toolbar color="indigo darken-4" dark>
                    <v-toolbar-title>Crear flujo de trabajo</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form ref="form" v-model="valid" >
                      <v-text-field required :rules="[v => !!v || 'Es requerido asignar un nombre al workflow']" rounded dense filled v-model="nombre_workflow" label=Nombre></v-text-field>
                      <v-textarea required :rules="[v => !!v || 'Es requerido asignar una descripcion al workflow']" rows="3" v-model="descripcion_workflow" outlined label=Descripcion></v-textarea>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="8">
                <v-card>
                    <v-toolbar color="indigo darken-4" dark>
                      <v-toolbar-title>Disena el flujo de trabajo</v-toolbar-title>
                    </v-toolbar>
                    <br>
                    <FlowyPlugin v-on:editar="edicion_confirmada"  v-on:terminar="terminar" :nodes=nodes />
                 </v-card>    
                </v-col>
            </v-row>
           </v-card> 
        </v-row>
        <v-dialog v-model="dialogEliminar" max-width="400">
            <v-card>
                <v-card-text>
                    <h3 class="my-5" style="text-align:center;color:red">Proceso de eliminacion</h3>
                    <v-divider></v-divider>
                    <p>Estas seguro que desear eliminar el workflow: </p>
                    <v-divider></v-divider>
                    <h4 style="text-align:center;color:red">{{nombre_workflow_eliminar}}</h4>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogEliminar=false" text color="red">Cerrar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="eliminacion_confirmada" text color="indigo">Aceptar <v-icon class="mx-3">mdi-delete</v-icon></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      </template>
  </EstructuraBasica>
</template>
<script>

import ToolbarTitle from '../../components/admin/ToolbarTitle'
import WorkFlow from '../../components/admin/WorkFlow'
//import Elementos from '../../components/admin/Elementos'
import FlowyPlugin from "../../components/admin/flowy-vue"
import buscador from '../../mixins/buscador'
const axios = require("axios").default;
export default {
  mixins: [buscador],
    components: {
        ToolbarTitle,
        FlowyPlugin,
        WorkFlow,
        //Elementos,

    },
    data () {
      return {
        dialogWorkFlow: false,

        dialogEliminar: false,
        nombre_workflow_eliminar: '',
        objectId: '',

        index: -1,
        valid: true,

        nombre_workflow: '',
        descripcion_workflow: '',
        workflows: [],
        nodes: [],
        img: require('../../assets/workflows.png'),
       //------------Variables del mixin--------------//
       //search: null, Variable que esta en el mixin 
       //Items: [],
       //ItemsFilter
       //----------------------------------------------//

        type: "number",
        number: 100,
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      }
    },
    beforeDestroy(){
      this.$store.state.admin.areas = []   
      this.$store.state.admin.proceso='crear' 
    },
    computed: {
    //Las computadas son del scroll
      target() {
        const value = this[this.type];
        if (!isNaN(value)) return Number(value);
        else return value;
      },
      options() {
        return {
          duration: this.duration,
          offset: this.offset,
          easing: this.easing,
        };
      },
    },

   async created(){
      
      this.$store.commit('auth','parse')
      this.consultar_areas()
           try {
              let response = await axios.get('https://audidoc.educandote.co/parse/classes/prueba01workflows',
                {
                    headers:{
                            'Content-Type' : 'application/json',
                            'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                            'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                            'Authorization': this.$store.state.header
                        }
                })

             console.log(response) 
            //this.workflows = response.data.results
            this.items = response.data.results
            this.itemsFilter = response.data.results
          
          /*
            for (let index = 0; index < this.workflows[5].workflow.length; index++) {
              this.nodes.push(this.workflows[5].workflow[index])
              
            }
            console.log(this.nodes)
            this.dialogWorkFlow = true
            */
           
             
           } catch (error) {
              console.log(error)
              this.$store.commit('error','Ha ocurrido un error consultando los flujos de trabajo Intenta mas tarde')
           }
    },
    methods: {
      reset(){
           this.nombre_workflow = ''
           this.descripcion_workflow = ''
           this.dialogWorkFlow = false
           this.$refs.form.resetValidation()
           this.$store.state.admin.workflow = [] 
           this.$store.state.admin.proceso = 'crear'
      },
      async consultar_areas(){
        try {
          let response = await axios.get('https://audidoc.educandote.co/parse/classes/prueba01Sector',
                {
                    headers:{
                            'Content-Type' : 'application/json',
                            'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                            'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                            'Authorization': this.$store.state.header
                        }
                })
                console.log(response)
                for (let index = 0; index < response.data.results.length; index++) {
                 this.$store.state.admin.areas.push(response.data.results[index])
                  
                }
                let obj1 = {
                  nombre: 'Notificar al usuario',
                  objectId: 1
                }
                let obj2 = {
                  nombre: 'Fin del proceso',
                  objectId: 0
                }
                this.$store.state.admin.areas.push(obj1)
                this.$store.state.admin.areas.push(obj2)
        } catch (error) {
          console.log(error)
              this.$store.commit('error','Ha ocurrido un error consultando las areas de trabajo Intenta mas tarde')
        }
      },
       async terminar(){
           
           this.$store.commit('auth','parse')
           try {
              let response = await axios.post('https://audidoc.educandote.co/parse/classes/prueba01workflows',
                {
                    "nombre": this.nombre_workflow,
                    "descripcion": this.descripcion_workflow,
                    "workflow": this.$store.state.admin.workflow
                },
                {
                    headers:{
                            'Content-Type' : 'application/json',
                            'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                            'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                            'Authorization': this.$store.state.header
                        }
                })

             console.log(response)
             this.items.unshift({
               descripcion: this.descripcion_workflow,
               nombre: this.nombre_workflow,
               workflow: this.$store.state.admin.workflow,
               objectId: response.data.objectId
             }) 
             this.$store.state.admin.workflow = []
             this.nodes = []
             this.$store.commit('exitoso','El flujo de trabajo: '+this.nombre_workflow+ ' Ha sido creado de manera exitosa')
             
           } catch (error) {
              console.log(error)
              this.$store.commit('error','Ha ocurrido un error creando el flujo de trabajo: '+this.nombre_workflow+' Intenta mas tarde')
           }
           
          this.reset()
          
        },
        crear(){
            console.log('crear')
            this.$store.state.admin.guardar_nodos = Math.random() //Solo activa wl watch que almacena

            //Los nodos del workflow alla decide que evento emitir si creacion o edicion segun sea el proceso
           //Segin este la variablr this.$store.state.admin.proceso. la cual puede ser crear o editar
        },
        editar(flow,index){

            this.nombre_workflow = flow.nombre
            this.descripcion_workflow = flow.descripcion
            this.nodes = flow.workflow
            this.dialogWorkFlow = true
            this.objectId = flow.objectId
            this.index = index
            this.$vuetify.goTo(this.target, this.options);
            this.$store.state.admin.proceso = 'editar'
            console.log(flow)
            console.log('editando')
        },
      async edicion_confirmada(){
          console.log(this.objectId)
          
            try {
                this.$store.commit('auth','parse')
                let response = await axios.put('https://audidoc.educandote.co/parse/classes/prueba01workflows/'+this.objectId,
                {   
                    "nombre": this.nombre_workflow,
                    "descripcion": this.descripcion_workflow,
                    "workflow": this.$store.state.admin.workflow
                },
                {
                    headers:{
                            'Content-Type' : 'application/json',
                            'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                            'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                            'Authorization': this.$store.state.header
                        }
                })
                    console.log(response)

                    //Tengo la duda si aqui hay un bug no lo he detectado
                    //como el array que se recorre es itemsfilter que es el array de items filtrado
                    //Tambien debo hacer el cambio ?

                    this.items[this.index].nombre = this.nombre_workflow
                    this.items[this.index].descripcion = this.descripcion_workflow
                    this.items[this.index].objectId = this.objectId
                    this.items[this.index].workflow = this.$store.state.admin.workflow
                   
                    this.$store.commit('exitoso', 'El tipo documental: '+this.nombre_workflow+' ha sido actualizado de manera exitosa')
                    

            } catch (error) {
                console.log(error)
                this.$store.commit('error','Algo ha salido mal editando el tipo documental: '+this.nombre_workflow+', por favor intente mas tarde')
                //Mensaje de error de que no hay sectores creados
            }
            this.reset()
        },
         eliminar(flow,index){
           console.log(flow)
           this.nombre_workflow_eliminar = flow.nombre
           this.objectId = flow.objectId
           this.dialogEliminar = true
           this.index = index

           console.log('eliminar')
        },
        //Este proceso puede irse a un mixin de los cruds junto con el compoennte del dialogo dialogo de eliminar
       async eliminacion_confirmada(){
         console.log('Eliminando')
            console.log(this.objectId)
            this.dialogEliminar = false
            try {
                this.$store.commit('auth','parse')
                let response = await axios.delete('https://audidoc.educandote.co/parse/classes/prueba01workflows/'+this.objectId,
                {
                    headers:{
                            'Content-Type' : 'application/json',
                            'X-Parse-REST-API-Key': 'LASDK823JKHR87SDFJSDHF8DFHASFDF',
                            'X-Parse-Application-Id': 'KSDJFKASJFI3S8DSJFDH',
                            'Authorization': this.$store.state.header
                        }
                })
                this.items.splice(this.index,1)
                this.$store.commit('exitoso','el tipo documental: '+this.nombre_workflow_eliminar+', Ha sido eliminado exitosamente')
                console.log(response)
            } catch (error) {
               console.log(error) 
               this.$store.commit('error','Algo ha salido mal eliminando el tipo documental: '+this.nombre_workflow_eliminar+', por favor intente mas tarde')
            }

        },
         revisar(){
            console.log('revisar')
        }
        
    }
}
</script>