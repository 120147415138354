<template>
 <v-hover class="ma-3" v-slot="{ hover }">
    <v-card  width=220 rounded="md" elevation="5" >
        <v-card-text>
            <h4>{{flow.nombre}}</h4>
            <v-img
                    alt="user"
                    src="https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg"
                    class="mx-auto"
                    :aspect-ratio="16/9"
                >
                <v-expand-transition>
                        <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out indigo darken-2 v-card--reveal display-3 white--text"
                            style="height: 100%;"
                        >
                            <v-btn @click="$emit('editar')" dark> Editar <v-icon>mdi-pencil</v-icon></v-btn>
                        </div>
                </v-expand-transition>
            </v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
            <p> {{flow.descripcion}} </p>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('eliminar')" color="red" icon> 
                <v-icon>
                  mdi-delete
                </v-icon> 
            </v-btn>
        </v-card-actions>
    </v-card>
  </v-hover>  
</template>
<script>
export default {
    props: ['flow'],
    data(){
        return{
            show: false
        }
    }
}
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
</style>